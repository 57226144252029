import { Text } from '../../text'

export const BladeMediaDurationTimeInterval = ({
  timeInterval,
}: {
  timeInterval: string
}) => {
  return (
    <Text
      textColor="tertiary"
      textTransform="uppercase"
      fontSize="xs"
      fontWeight="semibold"
      className="pl-1"
    >
      {timeInterval}
    </Text>
  )
}
