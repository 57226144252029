import type { VideoDurationFixed } from '@mntn-dev/domain-types'
import { Text } from '../../text'
import { BladeMediaDurationTimeInterval } from './blade-media-duration-time-interval.tsx'

export const BladeMediaDurationFixed = ({
  duration,
  timeInterval,
}: {
  duration: VideoDurationFixed
  timeInterval: string
}) => {
  return (
    <>
      <Text fontWeight="semibold" textColor="info" fontSize="xs">
        {duration}
      </Text>

      <BladeMediaDurationTimeInterval timeInterval={timeInterval} />
    </>
  )
}
