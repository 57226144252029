import {
  type VideoDuration,
  isVideoDurationArray,
  isVideoDurationFixed,
  isVideoDurationRange,
} from '@mntn-dev/domain-types'
import { Fragment } from 'react'
import { Text } from '../../text/text.tsx'
import { BladeMediaDurationFixed } from './blade-media-duration-fixed.tsx'
import { BladeMediaDurationRange } from './blade-media-duration-range.tsx'

export const BladeMediaDuration = ({
  duration,
  timeInterval,
  t,
}: {
  duration: VideoDuration
  timeInterval: string
  t: (key: 'or') => string
}) => {
  const durations = isVideoDurationArray(duration) ? duration : [duration]

  return durations.map((duration, index) => (
    <Fragment key={`duration-${index + 1}`}>
      {isVideoDurationRange(duration) && (
        <BladeMediaDurationRange
          duration={duration}
          timeInterval={timeInterval}
        />
      )}

      {isVideoDurationFixed(duration) && (
        <BladeMediaDurationFixed
          duration={duration}
          timeInterval={timeInterval}
        />
      )}

      {index !== durations.length - 1 && (
        <Text
          fontSize="xs"
          textColor="secondary"
          textTransform="uppercase"
          fontWeight="semibold"
          className="px-0.5"
        >
          {t('or')}
        </Text>
      )}
    </Fragment>
  ))
}
