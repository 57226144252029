'use client'

import {
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import type { PropsWithChildren, ReactNode } from 'react'

type TooltipVariant = 'default' | 'info'

type TooltipProps = PropsWithChildren<{
  content: ReactNode
  delay?: number
  variant?: TooltipVariant
}>

const variantClassMap: Record<
  TooltipVariant,
  { content: string; arrow: string }
> = {
  default: {
    content: cn(themeBackgroundMap.container, themeTextColorMap.primary),
    arrow: 'fill-black/30',
  },
  info: {
    content: cn(themeBackgroundMap.info, themeTextColorMap['primary-inverse']),
    arrow: 'fill-primary-blue',
  },
}

const zIndex = 'z-50'

const Tooltip = ({
  children,
  content,
  delay,
  variant = 'default',
}: TooltipProps) => {
  const classes = variantClassMap[variant]

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delay}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cn(
              'rounded-lg p-3 text-xs leading-normal animate-drop-fade',
              zIndex,
              themeBackgroundBlurMap['blur-sm'],
              classes.content
            )}
            sideOffset={6}
          >
            {content}
            <RadixTooltip.Arrow
              width="16"
              height="8"
              className={cn(
                zIndex,
                themeBackgroundBlurMap['blur-sm'],
                classes.arrow
              )}
            />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

export { Tooltip }
