import type { VideoDurationRange } from '@mntn-dev/domain-types'
import { Text } from '../../text'
import { BladeMediaDurationTimeInterval } from './blade-media-duration-time-interval.tsx'

export const BladeMediaDurationRange = ({
  duration: { min, max },
  timeInterval,
}: {
  duration: VideoDurationRange
  timeInterval: string
}) => {
  return (
    <>
      <Text fontWeight="semibold" textColor="info" fontSize="xs">
        {min}
      </Text>

      {min !== max && (
        <>
          <Text
            textColor="secondary"
            textTransform="uppercase"
            fontWeight="semibold"
            fontSize="xs"
            className="px-0.5"
          >
            {'-'}
          </Text>

          <Text fontWeight="semibold" textColor="info" fontSize="xs">
            {max}
          </Text>
        </>
      )}

      <BladeMediaDurationTimeInterval timeInterval={timeInterval} />
    </>
  )
}
